<template>
  <main class="main page">

    <section class="section">
      <h1 class="section__heading">404 Not Found</h1>
    </section>

  </main>
</template>

<style lang="scss" scoped>
</style>
